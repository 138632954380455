import { Injectable, Type, inject } from '@angular/core';
import { equal } from '@konnektu/metastore';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ConditionBlockDef, ConditionDef } from '@konnektu/segments-view';
import { TenantFeaturesService } from '../clients/tenant-config';
import {
  DEFAULT_CONDITION_BLOCKS,
  PHARMA_SIMPLE_FILTER_TEMPLATES
} from './simple-filter-templates';

interface LocationState {
  value?: string | number | boolean;
  id?: string | number | boolean;
}

@Injectable({ providedIn: 'root' })
export class FilterConfigService {
  private readonly tenantFeatures = inject(TenantFeaturesService);

  getFilterTemplates() {
    return PHARMA_SIMPLE_FILTER_TEMPLATES.map((template) => ({
      ...template,
      conditionBlocks: this.getConditionBlocks()
    }));
  }

  private getConditionBlocks(): ConditionBlockDef[] {
    const isTextMode = this.tenantFeatures.features().showRegionCity;

    if (!isTextMode) {
      return DEFAULT_CONDITION_BLOCKS;
    }

    return DEFAULT_CONDITION_BLOCKS.map((block) => {
      if (block.id === 'targetAudienceBlock') {
        return {
          ...block,
          conditions: block.conditions
            .filter((condition) => condition.id !== 'clientCountry')
            .map((condition) => {
              if (condition.id === 'clientRegion') {
                return {
                  id: 'clientRegion',
                  label: condition.label,
                  type: 'string' as unknown as Type<'string'>,
                  createExpression: (state: LocationState | null) =>
                    state?.value
                      ? equal(
                          'User.UserWorkPlaces.Organization.OrganizationToAddresses.Address.Region',
                          state.value
                        )
                      : {}
                };
              }
              return condition;
            }) as ConditionDef[]
        };
      }
      return block;
    });
  }
}
