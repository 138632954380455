import { Injectable, computed, inject } from '@angular/core';
import { INSTANCE_CODE } from '@konnektu/tokens';
import { TenantCode, TenantFeatures } from './tenant-features.types';

@Injectable({ providedIn: 'root' })
export class TenantFeaturesService {
  private readonly tenantCode = inject(INSTANCE_CODE);

  readonly features = computed<TenantFeatures>(() => {
    switch (this.tenantCode) {
      case TenantCode.ABBOTT:
        return {
          showWorkplaceTab: false,
          showRegionCity: true
        };
      case TenantCode.DEMOABBOTT:
        return {
          showWorkplaceTab: false,
          showRegionCity: true
        };
      case TenantCode.VEROPHARM:
        return {
          showWorkplaceTab: true,
          showRegionCity: false
        };
      case TenantCode.ANI:
        return {
          showWorkplaceTab: true,
          showRegionCity: true
        };
      default:
        return {
          showWorkplaceTab: true,
          showRegionCity: false
        };
    }
  });
}
