import {
  Component,
  computed,
  DestroyRef,
  inject,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { isDefined } from '@konnektu/helpers';
import { JobsService } from '@konnektu/jobs';
import { SegmentResponseDto } from '@konnektu/segments-data';
import { TopBarService } from '@konnektu/sidebar-layout';
import { WIDGET_GROUP_CONFIG } from '@konnektu/widgets';
import { concatMap, filter, map, of, shareReplay } from 'rxjs';
import { SegmentCardComponentStore } from './store';
import { segmentCardWidgetGroup } from './widget-group';

@Component({
  selector: 'knk-segment-card',
  templateUrl: 'segment.component.html',
  providers: [
    {
      provide: WIDGET_GROUP_CONFIG,
      useValue: of(segmentCardWidgetGroup)
    },
    SegmentCardComponentStore
  ]
})
export class SegmentCardComponent implements OnInit {
  @ViewChild('actionMenu', { static: true }) actionMenu!: TemplateRef<void>;

  private readonly componentStore = inject(SegmentCardComponentStore);

  private readonly jobs = inject(JobsService);

  private readonly topBarService = inject(TopBarService);

  private readonly destroy = inject(DestroyRef);

  private readonly route = inject(ActivatedRoute);

  protected readonly segment = toSignal(
    this.route.data.pipe(map((data) => data['segment'] as SegmentResponseDto)),
    { requireSync: true }
  );

  protected readonly isSegmentExternal = computed(() => {
    const segment = this.segment();
    return segment.isExternal;
  });

  segment$ = this.componentStore.segment$;

  isLoading$ = this.componentStore.isLoading$;

  recountJobs$ = this.jobs.requestAllScheduled('jobs.segments.calculate').pipe(
    concatMap((jobs) =>
      this.componentStore.segment$.pipe(
        map((segment) => [segment, jobs] as const)
      )
    ),
    map(([segment, jobs]) =>
      jobs.filter((j) => j.job.payload['SegmentId'] === segment?.id)
    ),
    shareReplay(1)
  );

  ngOnInit(): void {
    this.topBarService.configureTopBar(
      {
        actionTemplate: this.actionMenu
      },
      this.destroy
    );

    this.componentStore.loadSegmentAndVersions$(
      this.route.paramMap.pipe(
        map((param) => param.get('segmentId')),
        filter(isDefined),
        map((param) => parseInt(param, 10))
      )
    );
  }

  recount() {
    this.componentStore.recountCurrentSegment$();
  }

  edit() {
    this.componentStore.editCurrentSegment$();
  }

  addRecountJob() {
    this.componentStore.editSegmentSchedule$(null);
  }

  editRecountJob(id: string, cron: string) {
    this.componentStore.editSegmentSchedule$([id, cron]);
  }

  deleteSegment() {
    this.componentStore.deleteSegment$();
  }
}
