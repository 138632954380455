export enum TenantCode {
  ANI = 'ani',
  ABBOTT = 'abbott',
  VEROPHARM = 'veropharm',
  DEMOABBOTT = 'demoabbott'
}

export interface TenantFeatures {
  showWorkplaceTab: boolean;
  showRegionCity: boolean;
}
